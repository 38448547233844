import { http } from './config'
import authHeader from './auth-header';

export default {

    sepultado: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-sepultamento/', {
            params: params,
            headers: authHeader() 
        })
    },


    sepultado_plano: (dataI, dataF, tipo, empresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'tipo': tipo,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/total-sepultamento-plano/', {
            params: params,
            headers: authHeader() 
        })
    },


    lista_sepultamento_xls: (dataI, dataF) => {
    
            const converteParaNull = (valor) => {
                return valor === null ? 'NULL' : valor;
            };

            const params = {
                'data-inicial': converteParaNull(dataI),
                'data-final': converteParaNull(dataF)
              };
    
           
              return http.get('/planilha-dash/lista-sepultamento-funeraria-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });
    
              
    },

    lista_sepultamento_xls: (dataI, dataF, empresas, tipo) => {
    
        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };

        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF),
            'empresa': empresas,
            'tipo': tipo
          };

          return http.get('/planilha-dash/lista-sepultamento-xls', {
            params: params,
            responseType: 'blob',
            headers: authHeader() 
          });
        
    },

    lista_cemiterio_xls: (dataI, dataF) => {

        const converteParaNull = (valor) => {
            return valor === null ? 'NULL' : valor;
        };
        const params = {
            'data-inicial': converteParaNull(dataI),
            'data-final': converteParaNull(dataF)
          };

          return http.get('/planilha-dash/lista-sepultamento-cemiterio-xls', {
            params: params,
            responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
            headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui

          });
    },


    total_imediato: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-imediato/', {
            params: params,
            headers: authHeader() 
        })
    },



    valor_imediato: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/valor-imediato/', {
            params: params,
            headers: authHeader() 
        })
    },



    total_preventivo: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/total-preventivo/', {
            params: params,
            headers: authHeader() 
        })
    },

    valor_preventivo: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/valor-preventivo/', {
            params: params,
            headers: authHeader() 
        })
    },
    sepultameto_empresa: (dataI, dataF, empresas) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': empresas
        }

        return http.get('/sepultamento-dash/sepultamento-empresa/', {
            params: params,
            headers: authHeader() 
              
        })
    },

    sepultamento_empresa_periodo: (dataI, dataF, empresas) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': empresas
        }

        return http.get('/sepultamento-dash/sepultamento-empresa-periodo/', {
            params: params,
            headers: authHeader() 
              
        })
    },

    sepultamento_obitos_portal: (dataI, dataF, complemento) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'complemento': complemento
        }

        return http.get('/sepultamento-dash/deaths/', {
            params: params,
            headers: authHeader() 
              
        })
    },

    sepultameto_funeraria_previda: (dataI, dataF, tipo, empresa) => {
        
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'tipo': tipo,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/total-funeraria-previda', {
            params: params,
            headers: authHeader() 
        })
    },

    sepultameto_funeraria: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/sepultamento-funeraria/', {
            params: params,
            headers: authHeader() 
        })
    },


    sepultamento_plano: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }
        return http.get('/sepultamento-dash/sepultamento-plano/', {
            params: params,
            headers: authHeader() 
              
        })
    },


    imediato_parcelamento: (dataI, dataF) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
        }

        return http.get('/sepultamento-dash/imediato-parcelamento/', {
            params: params,
            headers: authHeader() 
              
        })
    },


    preventivo_parcelamento: (dataI, dataF, empresa) => {
        const params = {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': empresa,
        }

        return http.get('/sepultamento-dash/preventivo-parcelamento/', {
           params: params,
            headers: authHeader() 
              
        })
    },


    total_jazigo: (texto) => {
        return http.get('/sepultamento-dash/total-jazigo/'+texto, { headers: authHeader() })
    },

    jazigo_vendido: (texto) => {
        return http.get('/sepultamento-dash/jazigo-vendido/'+texto, { headers: authHeader() })
    },

    total_gavetas: (texto) => {
        return http.get('/sepultamento-dash/total-gavetas/'+texto, { headers: authHeader() })
    },


    total_columbario_disponivel: (texto) => {
        return http.get('/sepultamento-dash/total-columbario-disponivel/'+texto, { headers: authHeader() })
    },


    total_gavetas_disponivel: (texto) => {
        return http.get('/sepultamento-dash/total-gavetas-disponivel/'+texto, { headers: authHeader() })
    },

    total_exumados: (texto) => {
        return http.get('/sepultamento-dash/total-exumados/'+texto, { headers: authHeader() })
    },

    total_cremados: (texto) => {
        return http.get('/sepultamento-dash/total-cremados/'+texto, { headers: authHeader() })
    },


   

}
